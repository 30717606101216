import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../layout/footer";
import Master from "../../layout/master";
import Topbar from "../../layout/topbar";
import { company } from "../../validation/validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APP_URL } from "../../validation/basic";
import Loader from "../../loader/loader";
import axios from "axios";
const Pricing = () => {
  const [permissionlist, setPermissionlist] = useState([]);
  const [accesspermission, setAccesspermission] = useState([]);
  const [menuids, setMenuids] = useState("");
  const [pricingPlan, setPricingPlan] = useState([]);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("token"));
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    LoadPricingPlan();
  }, []);

  const LoadPricingPlan = async () => {
    try {
      const pricing_plan = await axios.get(APP_URL + "list-pricing-plan", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const plans_details = JSON.parse(JSON.stringify(pricing_plan.data));
      if (plans_details.status == 1) {
        setPricingPlan(plans_details.data.plan_list);
      }
    } catch (e) {
      toast.error("something went wrong");
    }
  };

  /*------activate and deactivate---------*/

  const ActivatePlan = async (id) => {
    if (!window.confirm("Are you sure want to change this status")) {
      return false;
    } else {
      try {
        const activate_plan = await axios.get(
          APP_URL + "activate-pricing-plan/" + `${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const activate_plan_details = JSON.parse(
          JSON.stringify(activate_plan.data)
        );
        if (activate_plan_details.status == 1) {
          toast.success("Plan status change Successfull");
          LoadPricingPlan();
        }
      } catch (e) {
        toast.error("something went wrong");
      }
    }
  };

  /*------plan delete---------*/

  const DeletePlan = async (id) => {
    if (!window.confirm("Are you sure want to Delete this")) {
      return false;
    } else {
      try {
        const delete_plan = await axios.get(
          APP_URL + "delete-pricing-plan/" + `${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const plan_deleted_details = JSON.parse(
          JSON.stringify(delete_plan.data)
        );
        if (plan_deleted_details.status == 1) {
          toast.success("Plan deleted Successfull");
          LoadPricingPlan();
        }
      } catch (e) {
        toast.error("something went wrong");
      }
    }
  };

  return (
    <>
      <div id="app">
        <div id="main" className="layout-horizontal">
          <Topbar
            setMenuids={setMenuids}
            menuids={menuids}
            setAccesspermission={setAccesspermission}
            accesspermission={accesspermission}
            setPermissionlist={setPermissionlist}
            permissionlist={permissionlist}
          />
          <ToastContainer />
          <div className="content-wrapper container">
            <div className="page-content">
              <section className="row">
                <div className="col-12 col-lg-12">
                  <section className="section">
                    <div className="row" id="table-hover-row">
                      <div className="col-12">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{ borderBottom: "1px solid" }}
                          >
                            <h5>Pricing Plan</h5>
                          </div>
                          <div className="card-body">
                            <div className="col-md-12 mt-3">
                              <div className="buttons">
                                {permissionlist &&
                                  permissionlist.map((p, ind) => (
                                    <>
                                      {accesspermission.some(
                                        (item1) => p.id == item1
                                      ) == true ? (
                                        <>
                                          {p.permission == "Add Plan" ? (
                                            <Link to="/settings/add-pricing-plan">
                                              <button className="btn btn-success rounded-pill">
                                                <i className="fa fa-plus"></i>{" "}
                                                Add Plan
                                              </button>
                                            </Link>
                                          ) : null}
                                        </>
                                      ) : null}
                                    </>
                                  ))}
                              </div>
                            </div>
                            <div className="col-md-12 mt-3">
                              {pricingPlan.length > 0 ? (
                                <>
                                  <div className="wrapper">
                                    {pricingPlan.map((item, index) => (
                                      <div
                                        className="pricing-table gprice-single"
                                        style={{ background: "#fff" }}
                                        key={item.id}
                                      >
                                        <div
                                          className={
                                            "active-label " +
                                            (item.status == 1
                                              ? "activate"
                                              : "deactivate")
                                          }
                                        >
                                          <p>
                                            {item.status == 1
                                              ? "Activate"
                                              : "Deactivate"}
                                          </p>
                                        </div>
                                        <div className="head">
                                          <h4 className="title">
                                            {item.title}
                                          </h4>
                                        </div>
                                        <div className="content">
                                          <div className="price">
                                            <h1>
                                              {item.currency_type == 1
                                                ? "₹"
                                                : "$"}
                                              {item.new_price}
                                            </h1>
                                          </div>
                                          <ul className="text-center">
                                            {item.plan_features.map(
                                              (item1, index1) => (
                                                <>
                                                  <li className="text-center">
                                                    {item1}
                                                  </li>
                                                </>
                                              )
                                            )}
                                          </ul>
                                          <div className="buttons">
                                            {permissionlist &&
                                              permissionlist.map((p, ind) => (
                                                <>
                                                  {accesspermission.some(
                                                    (item1) => p.id == item1
                                                  ) == true ? (
                                                    <>
                                                      {p.permission ==
                                                      "Activate" ? (
                                                        <button
                                                          className="btn btn-warning rounded-pill btn-custom"
                                                          onClick={() =>
                                                            ActivatePlan(
                                                              item.id
                                                            )
                                                          }
                                                        >
                                                          <i className="fa fa-ban"></i>
                                                        </button>
                                                      ) : null}
                                                      {p.permission ==
                                                      "Edit Plan" ? (
                                                        <Link
                                                          to={
                                                            "/settings/add-pricing-plan?plan_id=" +
                                                            item.id
                                                          }
                                                        >
                                                          <button className="btn btn-success rounded-pill btn-custom">
                                                            <i className="fa fa-pencil"></i>
                                                          </button>
                                                        </Link>
                                                      ) : null}
                                                      {p.permission ==
                                                      "Delete" ? (
                                                        <button
                                                          className="btn btn-danger rounded-pill btn-custom"
                                                          onClick={() =>
                                                            DeletePlan(item.id)
                                                          }
                                                        >
                                                          <i className="fa fa-trash"></i>
                                                        </button>
                                                      ) : null}
                                                    </>
                                                  ) : null}
                                                </>
                                              ))}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-md-12 text-center">
                                    <p>No Plan Available !!</p>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
